import * as React from "react";
import "animate.css/animate.min.css";
import LottieAnimation from "../components/lootie/Lottie";
import Layout from "../components/layout/layout";
import HomePageHero from "../sections/homepageHero/hompageHero";
import SectionWithIcons from "../sections/sectionWithIcons/sectionWithIcons";
import BlocksWithIconsTwo from "../sections/blocksWithIconsTwo/BlocksWithIconsTwo";
import SectionWithText from "../sections/sectionWithText/sectionWithText";
import Products from "../sections/products/Products";
import Community from "../sections/community/community";
import LatestNews from "../sections/latestNews/LatestNews";
import Blocks from "../sections/blocks/blocks";
import CustomLink from "../components/customLink/CustomLink";
import FadeIn from "../components/fade/fade";

import CompatibilityJson from "../json/Compatibility.json";
import SecurityArchitectureJson from "../json/Security_Architecture.json";

import LercStandardJson from "../json/LERC20_Integration.json";
import RealTimeFreezingJson from "../json/Real-time_Freezing.json";
import EfficientJson from "../json/Effiecient_evidence-based.json";
import TimelyRecoveryJson from "../json/Timely_recovery.json";
import FeesOnlyChargedJson from "../json/Fees_only_Charged.json";
import AdvancedExploitJson from "../json/Advanced_Exploit.json";

const sectionWithIconsData = {
  sectionColor: "green",
  titleMaxWidth: "81.6rem",
  title:
    "Lossless protocol is designed to reinforce token security framework, ensuring project creators and their communities have a fail-safe against malicious exploits. ",
  items: [
    {
      icon: <LottieAnimation animationJson={LercStandardJson} />,
      title: "Seamless LERC-20 standard integration",
    },
    {
      icon: <LottieAnimation animationJson={RealTimeFreezingJson} />,
      title: "Automated real-time freezing of suspicious transactions",
    },
    {
      icon: <LottieAnimation animationJson={EfficientJson} />,
      title: "Efficient and evidence-based resolution mechanism",
    },
    {
      icon: <LottieAnimation animationJson={TimelyRecoveryJson} />,
      title: "Timely recovery of stolen funds",
    },
    {
      icon: <LottieAnimation animationJson={FeesOnlyChargedJson} />,
      title: "Fees only charged on prevented financial loss",
    },
    {
      icon: <LottieAnimation animationJson={AdvancedExploitJson} />,
      title: "Advanced exploit identification and mitigation tools",
    },
  ],
};

const sectionWithTwoBlocksData = {
  title: "<span>LERC-20</span> Token Standard",
  text: "Technology behind Lossless",
  align: "center",
  items: [
    {
      title: "Compatibility",
      text: "LERC-20 is a unique extension of the widely-known and trusted ERC-20 standard for fungible tokens. It has the same methods and interface as the tried-and-tested ERC-20 standard, and is fully compatible with all of the DeFi building blocks already in place.",
      icon: <LottieAnimation animationJson={CompatibilityJson} />,
    },
    {
      title: "Security Architecture",
      text: "Adoption of the LERC-20 standard enhances token security architecture without compromising prior integrations or disrupting existing workflows.",

      icon: <LottieAnimation animationJson={SecurityArchitectureJson} />,
    },
  ],
  button: (
    <CustomLink
      text="Technical LERC-20 Specification"
      size="small"
      type="blue"
      url="https://lossless-cash.gitbook.io/lossless/technical-reference/lerc20"
      blank={true}
    />
  ),
};

const sectionWithTextData = {
  title:
    "<span>Leaders</span> in Web3 security architecture and exploit mitigation tools",
  text: [
    "Lossless protocol implements an additional layer of blockchain transaction security for ERC-20 standard tokens, mitigating the financial impact of smart contract exploits and private key theft.",
    "Lossless protocol utilises community-driven threat identification tools and a unique stake-based reporting system to identify suspicious transactions, providing real-time protection.",
  ],
  button: {
    type: "blue",
    size: "small",
    text: "About us",
    url: "/about",
    internal: true,
  },
};

const IndexPage = () => {
  return (
    <Layout pageTitle="Homepage - Lossless">
      <HomePageHero></HomePageHero>
      <FadeIn>
        <Blocks />
      </FadeIn>
      <FadeIn>
        <SectionWithText
          title={sectionWithTextData.title}
          text={sectionWithTextData.text}
          button={sectionWithTextData.button}
        ></SectionWithText>
      </FadeIn>
      <FadeIn>
        <SectionWithIcons
          sectionColor={sectionWithIconsData.sectionColor}
          titleMaxWidth={sectionWithIconsData.titleMaxWidth}
          title={sectionWithIconsData.title}
          items={sectionWithIconsData.items}
          button={sectionWithIconsData.button}
        ></SectionWithIcons>
      </FadeIn>
      <FadeIn>
        <BlocksWithIconsTwo
          items={sectionWithTwoBlocksData.items}
          align={sectionWithTwoBlocksData.align}
          title={sectionWithTwoBlocksData.title}
          text={sectionWithTwoBlocksData.text}
          button={sectionWithTwoBlocksData.button}
        />
      </FadeIn>
      <Products />
      <FadeIn>
        <Community />
      </FadeIn>
      <FadeIn>
        <LatestNews />
      </FadeIn>
    </Layout>
  );
};

export default IndexPage;
