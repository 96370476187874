import * as React from "react";
import * as styles from "./blocks.module.scss";
import Container from "../../components/container/container";
import Icon from "../../svg/chains/etherium.svg";
import Icon2 from "../../svg/chains/bnb.svg";
import Icon3 from "../../svg/chains/polygon.svg";
import Icon4 from "../../svg/chains/avalanche.svg";
import Icon5 from "../../svg/chains/harmony.svg";
import Icon6 from "../../svg/chains/fantom.svg";
import WarningIcon from "../../svg/warning.svg";

const Blocks = ({ title, text, button }) => {
  return (
    <section className={styles.blocks}>
      <Container>
        <div className={styles.blocksWrap}>
          <div className={styles.blocksItem}>
            <h3>Supported chains</h3>
            <div className={styles.blockItemContent}>
              <Icon />
              <Icon2 />
              <Icon3 />
              <Icon4 />
              <Icon5 />
              <Icon6 />
            </div>
          </div>
          <div className={styles.blocksItem}>
            <h3>Lossless protected tokens</h3>
            <div className={styles.blockItemContent}>
              <p>20</p>
            </div>
          </div>
          <div className={styles.blocksItem}>
            <h3>Total Value Protected</h3>
            <div className={styles.blockItemContent}>
              <p>$18,570,304</p>
              <div className={styles.blockItemTooltipTrigger}>
                <WarningIcon />
                <div className={styles.blockItemTooltip}>
                  Total US dollar value protected by Lossless protocol. Updated
                  June 07, 2022
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Blocks;
