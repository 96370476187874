import * as React from "react";
import * as styles from "./community.module.scss";
import Container from "../../components/container/container";
import TwitterLogo from "../../svg/twitter.svg";
import DiscordLogo from "../../svg/discord.svg";
import TelegramLogo from "../../svg/telegram.svg";
import LinkedinLogo from "../../svg/linkedin-big.svg";
import YoutubeLogo from "../../svg/youtube.svg";

const socialNetworks = [
  {
    logo: <TwitterLogo />,
    title: "Twitter",
    url: "https://twitter.com/losslessdefi",
  },
  {
    logo: <TelegramLogo />,
    title: "Telegram",
    url: "https://t.me/losslesscash",
  },
  {
    logo: <DiscordLogo />,
    title: "Discord",
    url: "https://discord.com/invite/gTAWRKWnbj",
  },
  {
    logo: <YoutubeLogo />,
    title: "Youtube",
    url: "https://www.youtube.com/c/LosslessDeFi",
  },
  {
    logo: <LinkedinLogo />,
    title: "Linkedin",
    url: "https://www.linkedin.com/company/losslessdefi/",
  },
];

const Community = () => {
  return (
    <section className={styles.community}>
      <Container>
        <h2>Lossless Community</h2>
        <p>
          Join our global community of professional white-hat hackers, token
          creators and security enthusiasts, united in their pursuit to rid the
          industry of malicious actors.
        </p>
        <ul className={styles.socials}>
          {socialNetworks.map((social, index) => (
            <li key={index} className={styles.socialsItem}>
              <a
                href={social.url}
                className={styles.socialsLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                {social.logo}
                <p>{social.title}</p>
              </a>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
};

export default Community;
