import { useRef } from "react";
import React, { useState, useEffect } from "react";
import * as styles from "./LatestNews.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import Container from "../../components/container/container";
import CustomLink from "../../components/customLink/CustomLink";
import SectionPattern from "../../svg/section-pattern-news.svg";
import parse from "html-react-parser";
import ArrowRight from "../../svg/arrow-right.svg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

const LatestNews = () => {
  const [latestArticles, setLatestArticles] = useState([]);
  useEffect(() => {
    fetch(
      `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@losslesscash`
    )
      .then((response) => response.json())
      .then((resultData) => {
        let data = resultData.items;

        for (const [key, value] of Object.entries(data)) {
          let description = data[key].description;
          description = description.substring(
            description.indexOf("<p>") + 3,
            description.indexOf("</p>")
          );
          description = description.replace(/(<([^>]+)>)/gi, "");
          data[key].description = description;
        }
        setLatestArticles(resultData.items);
      });
  }, []);

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  return (
    <section className={styles.news}>
      <div className={styles.sectionPattern}>
        <SectionPattern />
      </div>
      <Container>
        <h2>Industry News & Insights</h2>
        <div className={styles.newsSwiper}>
          <Swiper
            modules={[Navigation]}
            spaceBetween={24}
            slidesPerView={1}
            grabCursor="true"
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            breakpoints={{
              767: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
            }}
          >
            {latestArticles.map((article, index) => (
              <SwiperSlide className={styles.newsItem} key={index}>
                <a
                  href={article.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className={styles.newsItemImage}>
                    <img src={article.thumbnail} />
                  </div>
                  <h3>{article.title}</h3>
                  <p>{parse(article.description)}</p>
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className={styles.navigation}>
            <button
              className={`${styles.navigationItem} ${styles.navigationItemLeft}`}
              ref={navigationPrevRef}
            >
              <ArrowRight />
            </button>
            <button
              className={`${styles.navigationItem} ${styles.navigationItemRight}`}
              ref={navigationNextRef}
            >
              <ArrowRight />
            </button>
          </div>
        </div>
        <div className={styles.newsButtons}>
          <CustomLink
            text="Read all news"
            type="blue"
            size="small"
            url="https://losslesscash.medium.com/"
            blank
          ></CustomLink>
        </div>
      </Container>
    </section>
  );
};

export default LatestNews;
