// extracted by mini-css-extract-plugin
export var navigation = "LatestNews-module--navigation--bbNMq";
export var navigationItem = "LatestNews-module--navigation-item--hDAhu";
export var navigationItemLeft = "LatestNews-module--navigation-item--left--Q23An";
export var navigationItemRight = "LatestNews-module--navigation-item--right--YyCbA";
export var news = "LatestNews-module--news--c3aJL";
export var newsButtons = "LatestNews-module--news-buttons--GLO++";
export var newsItem = "LatestNews-module--news-item--qv-jt";
export var newsItemImage = "LatestNews-module--news-item-image--gREPd";
export var newsSwiper = "LatestNews-module--news-swiper--mKmPa";
export var sectionPattern = "LatestNews-module--section-pattern--mpghu";