import React, { useState, useEffect } from "react";
import * as styles from "./homepageHero.module.scss";
import Container from "../../components/container/container";
import CustomLink from "../../components/customLink/CustomLink";
import HeroVideo from "../../videos/homepage.mp4";
import cn from "classnames";

const HomePageHero = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    setIsLoaded(true);
  }, []);
  return (
    <section className={styles.hero}>
      <video
        autoPlay
        muted
        loop
        playsInline
        className={cn(isLoaded && styles.heroIsActive)}
      >
        <source src={HeroVideo} type="video/mp4" />
      </video>
      <Container>
        <div className={styles.heroWrap}>
          <h1>Restoring Trust in Web3 Security</h1>
          <p>
            Lossless incorporates a new layer of blockchain transaction
            security, protecting selected projects and their communities from
            malicious exploits and the associated financial loss.
          </p>
          <div className={styles.heroButtons}>
            <CustomLink
              text="Documentation"
              url="https://lossless-cash.gitbook.io/lossless/technical-reference/hack-mitigation-protocol"
              type="blue"
              size="big"
              blank
            ></CustomLink>
            <CustomLink
              text="Platform"
              url="https://platform.lossless.cash/"
              type="blur"
              size="big"
              blank
            ></CustomLink>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default HomePageHero;
