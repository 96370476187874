import * as React from "react";
import * as styles from "./sectionWithText.module.scss";
import parse from "html-react-parser";
import Container from "../../components/container/container";
import CustomLink from "../../components/customLink/CustomLink";

const SectionWithText = ({ title, text, button }) => {
  const renderButtons = () => {
    if (button) {
      return (
        <div className={styles.sectionButton}>
          <CustomLink
            type={button.type}
            size={button.size}
            text={button.text}
            url={button.url}
            internal={button.internal}
          ></CustomLink>
        </div>
      );
    }
  };

  return (
    <section className={styles.section}>
      <Container>
        <div className={styles.sectionWrap}>
          <div className={styles.sectionLeft}>
            <h2>{parse(title)}</h2>
          </div>
          <div className={styles.sectionRight}>
            <div className={styles.sectionText}>
              {text.map((item, index) => {
                return <p key={index}>{item}</p>;
              })}
            </div>

            {renderButtons()}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default SectionWithText;
